

.footContainer  {
  color:rgb(27, 27, 27);
   border-radius: 2pt;
   box-sizing: border-box;
width: 80%;
background-color: transparent;
justify-content: center;
display: block;
font-size: 13pt;
padding-top: 2%;
margin-left: auto;
margin-right: auto;

} 
  
.footer-row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.footer-column {
  text-align: left;
  flex: 1; /* Equal width for both columns */
}

/* Styles for mobile screens */
@media screen and (max-width: 600px) {
  .footer-column {
    flex: 100%; /* Make each column take full width */
  }
}
.footer-row:after {
  content: "";
  display: table;
  clear: both;
}



.footContainer h2 {
  font-weight: 200;
margin: 0;
  text-align: left;
  font-size:24pt;
  margin-top: 4%;
  
}

.footContainer label {
  font-size: 13pt;

}
.footContainer input {
  font-size:13pt;
  background-color: rgba(251, 125, 57, 0.709);
  border-radius: 10pt;
  border-left:2pt solid rgb(255, 111, 8);
  border-bottom:2pt solid rgb(255, 111, 8);
  width: 90% ;
  padding:4pt;
  display: block;
  border-right: transparent;
  border-top: transparent;

}


.footContainer  .button {
  padding: 7pt;
  margin-top:20px;
border-radius: 15pt;
border:1pt dashed black;
background-color: transparent;
display: block;
color:black;
font-weight: 300;
font-family: "Athiti";
}

.footContainer  .button:hover{
  background-color: rgba(251, 125, 57, 0.709);

}
.caroline-side {
  height: 300px;
  background-color: transparent;
}

.caroline-side img {
  width: 100%;
  height: 35%;
  background-color: transparent;
  margin-top: 45px;
  display: inline;
  align-content: center;
  margin-bottom: 3%;
  box-sizing: border-box;
  object-fit: contain;
}


input#message {
  height: 70px;
  width:90% ;
  word-wrap: break-word;

}



.grid-container {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 4%;
}





.project-header h1 {
    color:black;
    font-size: 40px;
   font-weight: 300;
   font-family: "athiti";
   background-color: transparent;
   width:90%;
   height: 90px;
   box-sizing: border-box;
   word-wrap:break-word;
  overflow: scroll;
   margin-left: auto;
   margin-right: auto;
display: inline;

}

.project-header  {
background-color:transparent;
width: 90%;
margin-left: auto;
margin-right: auto;
display: block;
box-sizing: border-box;
text-wrap: wrap;
margin-bottom: 30px;
margin-top: 30px;
}

.project-description {
    color:black;
font-size: 14pt;
margin-bottom: 1%;
text-align: left;
box-sizing: border-box;
padding-left:2%;
padding-right: 2%;
display: block;
text-wrap: wrap;
width: 90%;
height: 130px;
overflow-y: scroll;
margin-left: auto;
margin-right: auto;
background-color: rgba(255, 255, 255, 0.776);
border-radius: 6pt;
margin-top: 2%;
}




/* width */



.project-description::-webkit-scrollbar {
  width: 12px;
 background-color: rgba(216, 216, 216, 0.229);
}

/* Track */
.project-description::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.805);
  border-radius: 6pt;
}

.project-description::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 10px white(198, 198, 198, 0.805);
  border-radius: 6pt;
margin:2%;
border: 1px solid rgb(206, 206, 206);

}
/* Handle */
.project-description::-webkit-scrollbar-thumb {
  background: rgba(249, 143, 4, 0.86);
  border-radius: 6pt;
  background: rgba(249, 143, 4, 0.561);

}
.project-description::-webkit-scrollbar-thumb:hover {
  background: rgba(249, 143, 4, 0.86);
  border-radius: 6pt;
  border: 1pt solid rgba(249, 143, 4, 0.86);

}

@media screen and (max-width:768px) {
  .project-description::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px rgba(122, 102, 102, 0);
    border-radius: 6pt;
  margin:2%;
  }
  .project-description::-webkit-scrollbar {
    width: 8px;
    
   background-color: transparent(216, 216, 216, 0);
  }
  .project-description::-webkit-scrollbar-thumb {
    background: rgba(249, 143, 4, 0.86);
    border-radius: 6pt;
    background: rgba(249, 143, 4, 0.561);
  
  }
}







.image-grid {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: 30% 30% 30% ; /*Make the grid smaller than the container*/
width: 100%;
box-sizing: border-box;
height: fit-content;
background-color: transparent;
  }


.each-image {
    border-radius: 6pt;
    background-color: white;
height:100%;
object-fit:contain;
box-sizing: border-box;

}

.each-image:hover {
scale: 1.3;    

}

 a {
    font-size: 20pt;
    margin-bottom: 1%;
    text-align: center;
    display: block;
    width: 100%;
    color: black;
position: relative;
  }
 a:hover{
  color:rgba(3, 91, 255, 0.84);
 
  }
   
  .section-title{
    font-size: 40pt;
    text-align: center;
    width: 100%;
    display: block;
    font-weight: 200;
    background-color: transparent;
}


#projects-link {
  width: 100%;
justify-content: center;
box-sizing: border-box;
display: block;
}

.project img {
height: 90%;
display: block;
  object-fit: cover;
}

.project {
  width: 39.25%; /* Adjust the width as needed */
  margin: 1.5%; /* Adjust the margin as needed */
  border-radius: 6pt;
  border: 1.5pt dotted black;
}
 .project:hover {
background-color: rgba(255, 255, 255, 0.776);
border-radius: 8pt;
scale: 1.03;
border: .5px solid;
box-shadow: -5px 6px 7px 4px   rgba(38, 103, 215, 0.684);
;
 }


/* Define styles for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
  .project {
    width: 90%; /* Set width to 100% for smaller screens */
    margin: 0; /* Remove margin for smaller screens */
margin-bottom: 4%;
  }
  .project:hover {
    background-color: rgba(244, 241, 238, 0.576);
    border-radius: 6pt;
    scale:1;
     }
}
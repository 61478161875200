#bar {
  width: 28px;
  height: 2px;
  background-color: rgb(0, 81, 255);
 display: block;
  margin:3px ;
}


.wholeContainer a {
    text-decoration: none;
    color:black;
    background-color: rgba(251, 125, 57, 0.709);
  font-family: 'Athiti';
  font-size: 11pt;
  border-radius: 15pt;
position: relative;

}

.wholeContainer a:hover {
  background-color: rgb(255, 132, 0);

}
.wholeContainer {
  float: right;
  display: block;
  text-align: right;
  text-decoration: none;

}
.bigger {
  position: fixed;
  z-index:15;
}

.wholeContainer button {
position: relative;
  margin:2%;
  border-color: transparent;
  background-color: transparent;
}
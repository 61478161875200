
  .fan-container {
width: 90%;
display: block;
justify-content: center;
margin-left: auto;
margin-right: auto;
overflow: hidden;
  }
  
  .swatch {
    display: flex;
    align-items: center;
    padding-left: 10%;
    width: 100%;
    margin-right: auto;
    margin-left: 0%;
    background-color: transparent;
box-sizing: border-box;
height: 170px;
justify-content: center;
margin-top: 0;
margin-bottom: 20px;

    }
  .swatch div {
    width: 32px;
    height:130px;
    border-radius: 15pt;
    border-top: solid 2px rgba(255, 255, 255, 0.2);
    border-left: solid 3px rgba(255, 255, 255, 0.2);
    border-bottom: solid 3px rgba(6, 0, 0, 0.2);
    text-align: center;
    transform-origin: center 90%;
    display: flex;
    backface-visibility: hidden;
    margin-left: -35px;
    transform: rotate(0%);
    margin-top: 2%;
    box-sizing: border;
    justify-content: center;

  }
  .swatch div:before {
    width: 8px;
    height: 8px;
    content: "";
    background-color: white;
    display: block;
    border-radius: 8px;
    bottom: 5px;
    position: absolute;
  }

  .swatch div:nth-child(1) {
    background-color:  #99dbc2f8;
    animation: swatch-purple-motion 5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  .swatch div:nth-child(2) {
    background-color: #a58cc5e4;
    animation: swatch-blue-motion 5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  .swatch div:nth-child(3) {
    background-color:  #ff8a23e4;
    animation: swatch-green-motion 5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  .swatch div:nth-child(4) {
    background-color:  #ff8800e4;
    animation: swatch-yellow-motion 5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  .swatch div:nth-child(5) {
    background-color:  #147ce4e4;
    animation: swatch-orange-motion 5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  .swatch div:nth-child(6) {
    background-color: #094cf6e4;
    animation: swatch-red-motion 5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  @keyframes swatch-purple-motion {
    0% {
      transform: rotate(0deg);
    }
    30%,
    70% {
      transform: rotate(-65deg);
    }
    90%,
    10% {
      transform: rotate(0deg);
    }
  }
  @keyframes swatch-blue-motion {
    0% {
      transform: rotate(0deg);
    }
    30%,
    70% {
      transform: rotate(-40deg);
    }
    90%,
    10% {
      transform: rotate(0deg);
    }
  }
  @keyframes swatch-green-motion {
    0% {
      transform: rotate(0deg);
    }
    30%,
    70% {
      transform: rotate(-15deg);
    }
    90%,
    10% {
      transform: rotate(0deg);
    }
  }
  @keyframes swatch-yellow-motion {
    0% {
      transform: rotate(0deg);
    }
    30%,
    70% {
      transform: rotate(15deg);
    }
    90%,
    10% {
      transform: rotate(0deg);
    }
  }
  @keyframes swatch-orange-motion {
    0% {
      transform: rotate(0deg);
    }
    30%,
    70% {
      transform: rotate(40deg);
    }
    90%,
    10% {
      transform: rotate(0deg);
    }
  }
  @keyframes swatch-red-motion {
    0% {
      transform: rotate(0deg);
    }
    30%,
    70% {
      transform: rotate(65deg);
    }
    90%,
    10% {
      transform: rotate(0deg);
    }
  }
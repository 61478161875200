.title {
    text-align: center;
    font-size:35pt;
    margin-top: 3%;
    font-weight: 200;
}

div.carousel {
    width: 65vw;
margin-left: auto;
margin-right: auto;
    border-radius: 6pt;
    display: flex;
    height: 300px;
    padding-top: 2%;
    padding:1%;
        background: linear-gradient(270deg, #48e2bcac, #0b80eeb7, #f0cd0ad7, rgb(186, 148, 228));
        background-size: 600% 600%;
    
        -webkit-animation: AnimationName 30s ease infinite;
        -moz-animation: AnimationName 30s ease infinite;
        animation: AnimationName 30s ease infinite;
    }
    
    @-webkit-keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
    @-moz-keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
    @keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }

.button-div {
    background-color: transparent(255, 255, 255, 0.558);
    position: relative;
    width: 65vw;
    margin-right: auto;
    margin-left: auto;
    border-radius: 6pt;
    display: block;
    margin-bottom: 4%;
    height: auto;
    padding: 2%;
}
.carousel-detail {
    width: 90%;
    border-radius: 6pt;
height: auto;
    display: block;
    margin-right: auto;
margin-left: auto;
}

.carousel-text {
    font-weight:300 ;
    margin-top: 0%;
    text-wrap: pretty;
margin-bottom: auto;
    text-align:left;
background-color: transparent;
font-size: 13pt;
width: 90%;
height: 115px;
border-radius: 8pt;
margin-right: auto;
margin-left: auto;
overflow-y: scroll;
padding: 1%;
}

.carousel img {
    display: block;
    background-color: transparent;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 3%;
    height: 200px;
    object-fit: contain;

}
.carousel .carousel-title {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: transparent;
display: block;
margin-top: 0px;
}

@media screen and (max-width:768px) {

div.carousel {
    height:auto;
    width: 80vw;
}
div.carousel .client-column{
    display: grid;
    justify-content: center;

}
div.carousel .client-row {
    background-color: transparent;
}

div.carousel .carousel-text::-webkit-scrollbar {
    width: 8px;
    border-radius: 8pt;
   background-color: rgba(0, 0, 0, 0.124);
  }
  
  /* Track */
div.carousel .carousel-text::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.269);
    border-radius: 6pt;
  }

  /* Handle */
div.carousel .carousel-text::-webkit-scrollbar-thumb {
    background-color: rgba(238, 238, 238, 0.32);
    border-radius: 6pt;
  
  } 

}






.carousel-title {
font-weight: 300;
text-align: center;
width: 90%;
font-size: 25pt;
display: block;
box-sizing: border-box;
padding-top: 2%;
background-color: transparent;
}

button.carousel-button {
    background-color: transparent;
    width: 50px;
    padding: 5pt;
    border-radius: 6pt;
    border:  transparent ;
color: black;
display: inline;

    font-size: 14pt;
}

.carousel-link {
    text-decoration: none;
    color: black;
    box-sizing: border-box;
    display: block;
    background-color: transparent;
    width: 90%;
 margin: 2%;
    align-items: auto;

}

.carousel-link:hover {
    text-decoration: none;
    color:#0077ffb7;

}

.client-column {
flex:50%;
display: flex;
box-sizing: border-box;
background-color: transparent;
margin: 2%;
}

.client-row {
    display: flex;
box-sizing: border-box;
}


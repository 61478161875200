
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200&family=Press+Start+2P&display=swap');


.blink {
    animation: 1.3s infinite blink;
    color: rgba(251, 125, 57, 0.709);
    font-size: 31pt;
    display:block;
    text-align: center;
line-height: 25pt;

}

@keyframes blink {
    0%, 15% {
        opacity: .5;
        color:rgba(255, 255, 255, 0.36);
    }
    16%, 100% {
        opacity: .7;
    }
}
.marqueeContainer {
    background:transparent;
    color:rgba(0, 0, 0, 0.683);
    width: 50%;
    transform: translateX(100%);
    animation: scroll-left 20s linear infinite;
    font-size: 9pt;
    line-height: 13pt;
margin-bottom: 1%;
margin-top: 3%;
}

@media screen and (max-width:768px){
    .marqueeContainer {
    scale: .6;
    }
}
  
@keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200&family=Belanosima&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@200;300&family=Bagel+Fat+One&family=Barlow:wght@200&family=Belanosima&family=Caprasimo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@200;300&family=Bagel+Fat+One&family=Barlow:wght@200&family=Belanosima&family=Bungee+Hairline&family=Caprasimo&family=Codystar:wght@300;400&family=Coiny&family=Londrina+Outline&family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300&family=Kavivanar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@200;300&family=Bagel+Fat+One&family=Barlow:wght@200&family=Belanosima&family=Bungee+Hairline&family=Caprasimo&family=Codystar:wght@300;400&family=Coiny&family=Londrina+Outline&family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300&family=Righteous&display=swap');



html {
 font-family: "Athiti";
 justify-content: center;
background: transparent;
align-items: center;
width: 100vw;
}


Index {
  min-width: 100vw;
  max-width:700px;
  height:100vh;
  display:inline-flex;
  justify-content: flex-start;
  align-items: center;
}



div {
  display: grid;
  text-align: center;
  grid-template-columns: repeat(1,100%);
justify-content: center;
justify-content: center;
width: 100%;
}





.circleWrapper {
	width: 100%;
	margin-bottom: 1%;
	float: left;
    background-color: transparent
    ;
		text-align: center;
		display:inline-flex;
  }
  
  .icon {
    transform: translate(-50%, -50%);
	text-align: center;
	width: 100%;
		float: left;
  }
  
  .circle {
    height: 200px;
	width: 200px;
    background-image: url(../img/me.jpg)  ;
    background-size: cover;
    animation: spin 20s linear infinite;
		    border:  1px black dashed;
  }
  
  .circleWrapper:active .circle {
    animation: spin 2s linear infinite;
  }
  
  


  @keyframes spin {
	0%,
	100% {
		border-radius: 30% 70% 60% 30% / 30% 52% 48% 70%;
		box-shadow: 0 -2vmin 4vmin transparent(231, 85, 6, 0.611) inset, 0 -4vmin 4vmin transparent inset,
			0 -2vmin 7vmin transparent inset;
	}

	10% {
		border-radius: 50% 50% 20% 80% / 25% 80% 20% 75%;
	}

	20% {
		border-radius: 67% 33% 47% 53% / 37% 20% 80% 63%;
	}

	30% {
		border-radius: 39% 61% 47% 53% / 37% 40% 60% 63%;
		box-shadow:transparent;
	}

	40% {
		border-radius: 39% 61% 82% 18% / 74% 40% 60% 26%;
	}

	50% {
		border-radius: 100%;
		box-shadow: 0 4vmin 16vmin rgba(250, 205, 4, 0.715) inset, 0 2vmin 5vmin transparent inset, 0 4vmin 4vmin transparent inset,
			0 6vmin 8vmin transparent(248, 225, 15) inset;
	}

	60% {
		border-radius: 50% 50% 53% 47% / 72% 69% 31% 28%;
	}

	70% {
		border-radius: 50% 50% 53% 47% / 26% 22% 78% 74%;
		box-shadow: 1vmin 1vmin 8vmin transparent inset,
			2vmin -1vmin 4vmin transparent inset, -1vmin -1vmin 16vmin transparent inset;
	}

	80% {
		border-radius: 50% 50% 53% 47% / 26% 69% 31% 74%;
	}

	90% {
		border-radius: 20% 80% 20% 80% / 20% 80% 20% 80%;
	}
}



@media  screen and (max-width:768px) {
	.circle {
		scale:.8;
	}
}

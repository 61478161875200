.container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    /* To evenly space the columns */
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    width:80%;

    /* Adjust according to your preference */
    /* margin: 0 auto; Center the container horizontally */
}


.sag-pop img {
    background-color: rgba(255, 102, 0, 0.676);
    border-radius: 8pt;
    padding: 2%;
    width: 50vw;
}

.column {
    align-items: start;

    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 4%;
    flex: 1;
    /* Each column takes up equal space */
    background-color: transparent;
    /* Background color for columns */
    border-radius: 8px;
 
margin-left: auto;
margin-right: auto;

}


.column #contact-a {
    text-decoration: none;
    font-size: 15pt;
    text-align: left;
    width: 80%;
    text-wrap: wrap;
    line-height: 15px;
    background-color: transparent;
    display: block;
    font-weight: 300;
}

.column #contact-a h4 {
    background-color: transparent;
    display: block;
    font-weight: 300;
}

.column h2 {
    text-align: left;
    background-color: transparent;
    font-size: 20pt;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    text-wrap: wrap;
    font-weight: 300;
}

h2#header-h2 {
    text-align: left;
    width: 90%;
    font-size: 20pt;
    background-color: transparent;
    align-self: end;
    font-weight: 300;

}

.column #sag-a {
    text-decoration: none;
    font-weight: 300;
    text-align: left;
    font-size: 15pt;
    display: inline-block;

}

a.wavy {
position: relative;
margin:0;
}
a.wavy span {
    position: relative;
    display: inline-block;
    color: rgb(0, 98, 255);
    background-color: transparent;

    animation: animate 1.5s linear infinite;
} 
a.wavy:hover {

    color: rgb(255, 132, 0);

} 

a.wavy span:nth-child(2) {
  animation-delay: 100ms;
} 

a.wavy span:nth-child(3) {
    animation-delay: 200ms;
  } 
  a.wavy span:nth-child(4) {
  animation-delay: 300ms;
} 
a.wavy span:nth-child(4) {
    animation-delay: 400ms;
  } 
  a.wavy span:nth-child(5) {
    animation-delay: 500ms;
  } 
  a.wavy span:nth-child(6) {
    animation-delay: 600ms;
  } 
  a.wavy span:nth-child(7) {
    animation-delay: 700ms;
  } 
  a.wavy span:nth-child(8) {
    animation-delay: 800ms;
  } 
  a.wavy span:nth-child(9) {
    animation-delay: 900ms;
  } 
  a.wavy span:nth-child(10) {
    animation-delay: 1000ms;
  } 
  a.wavy span:nth-child(11) {
    animation-delay: 1100ms;
  } 


@keyframes animate {
    0%
    {
        transform: translateY(0px);
    }
    20%
    {
        transform: translateY(-6px);
    }
    40%,100%
    {
        transform: translateY(0px);
    }
}

.column a:hover {
    color: rgb(53, 105, 238);

}

.column p {
    font-weight: 300;
    background-color: transparent;
    font-size: 14pt;
    text-align: left;
    margin: 0 auto;
    width: 90%;
    display: block;
    line-height: 40px;

}


@media only screen and (max-width: 600px) {
    .container {
        flex-direction: column;
        /* Change flex direction to stack columns vertically */
    }

    .column {
        margin-bottom: 20px;
        margin-left: auto;
        /* Add some spacing between stacked columns */
        margin-right: auto;
 
    }
}

.prerogative {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
font-size: 14pt;
text-align: left;
font-weight: 300;
background-color: rgba(255, 255, 255, 0.84);

}

#prerogative-h1 {
    text-align: center;
    font-size: 40pt;
    font-weight: 300;
    width: 100%;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-wrap: wrap;
}